import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('../views/StatisticsView.vue')
  },
  {
    path: '/warning',
    name: 'Warning',
    component: () => import('../views/WarningView.vue')
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/ReportView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = new VueRouter({
  scrollBehavior () {
		return {x: 0, y: 0, behavior: 'smooth'}
	},
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isAuthenticated () {
  return localStorage.getItem('token')
}
router.beforeEach((to, from, next) => {
  if(to.name !== 'Login' && !isAuthenticated()) next({name: 'Login'})
  else if (to.name == 'Login' && isAuthenticated()) next({name: 'Home'})
  else next()
})
export default router
