<template>
	<div class="fixed flex flex-row bg-gray-950 h-screen w-full overflow-hidden">
		<div class="h-12 bg-black w-full flex items-center justify-between fixed top-0 border-b border-gray-900 z-50">
			<div class="px-5 text-lg text-gray-300 font-medium">SPARING KITB</div>
			<div class="px-5 flex items-center space-x-3">
				<i class="icon-calendar3"></i>
				<div>{{date}}, {{time}}</div>
			</div>
			<div class="px-5 h-full flex items-center">
				<ul class="flex items-center h-full w-full">
					<li class="h-full relative">
						<button ref="notifications" @click="onRead" class="h-full px-5 hover:bg-gray-900 relative">
							<i class="icon-bell2"></i>
							<div v-show="unread" class="absolute top-1 left-6 rounded-full bg-red-500 text-xs text-red-50 flex items-center h-5 w-5 min-w-min px-1 justify-center">
								<div>{{unread}}</div>
							</div>	
						</button>
						<div v-show="showDropdownNotifications" class="absolute w-72 bg-gray-700 right-0 rounded-sm shadow-lg z-50">
							<div class="px-4 py-2 shadow-md">Early Warning</div>
							<ul ref="dropdown" v-if="notifications.length > 0" class=" max-h-96 h-96 overflow-y-auto">
								<li v-for="(notif, i) in notifications" :key="i" class="border-b border-gray-600 py-1 px-1">
									<div class="px-3 py-1 text-xs">{{notif['data'].added_at}}</div>
									<div class="flex justify-between px-3 font-semibold text-gray-300">
										<div>
											<div v-if="notif['data'].device_id == 1">INLET</div>
											<div v-if="notif['data'].device_id == 2">OUTLET</div>
											<div v-if="notif['data'].device_id == 3">RIVER</div>
										</div>
										<div class="flex space-x-2">
											<div>{{notif['data'].parameter}}</div>
											<div>{{notif['data'].value}}</div>
										</div>
									</div>
									<div class="px-3 mb-1">{{notif['data'].description}}</div>
								</li>
							</ul>
							<ul v-else class="flex items-center justify-center p-5">
								<i class="icon-warning22 icon-2x"></i>
							</ul>
							<div class="py-2 px-4 text-right border-t border-gray-600">
								<button v-show="notifications.length > 0" @click="deleteAll" class="text-right hover:text-red-300">
									<div class="flex items-center space-x-2">
										<i class="icon-trash"></i> 
										<div>Clear Notifications</div>
									</div>
								</button>
							</div>
						</div>
					</li>
					<li class="h-full">
						<button @click="logOut" class="px-3 h-full hover:text-gray-200 flex items-center"><i  class="icon-switch mr-2"></i> Log out</button>
					</li>
				</ul>
			</div>
		</div>
		<div class="grid grid-cols-12 w-full mt-12">
			<!-- SIDE BAR -->
			<div class="col-span-1 bg-gray-800 h-full">
				<ul>
					<li>
						<router-link to="/" class="flex items-center justify-center h-24 border-b border-gray-700" exact>
							<div class="text-center">
								<i class="icon-home4 icon-1x"></i>
								<div class="mt-1.5">Dashboard</div>
							</div>
						</router-link>
					</li>
					<li>
						<router-link to="/statistics" class="flex items-center justify-center h-24 border-b border-gray-700">
							<div class="text-center">
								<i class="icon-chart icon-1x"></i>
								<div class="mt-1.5">Statistics</div>
							</div>
						</router-link>
					</li>
					<li>
						<router-link to="/warning" class="flex items-center justify-center h-24 border-b border-gray-700">
							<div class="text-center">
								<i class="icon-warning icon-1x"></i>
								<div class="mt-1.5">Warning</div>
							</div>
						</router-link>
					</li>
					<li>
						<router-link to="/report" class="flex items-center justify-center h-24 border-b border-gray-700">
							<div class="text-center">
								<i class="icon-file-spreadsheet icon-1x"></i>
								<div class="mt-1.5">Report</div>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
			<!-- CONTENT -->
			<div ref="content" class="col-span-11 overflow-y-scroll">
				<transition name="fade" mode="out-in">
					<router-view/>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	data () {
		return {
			showDropdownNotifications: false,
			date: '',
			time: '',
			unread: '',
			notifications: []
		}
	},
	mounted () {
		document.body.addEventListener('click', this.closeMenu)
		this.getTime()
		this.getData()
		let app = this
		var channel = this.$pusher.subscribe('warning')
		channel.bind('push', function () {
			app.getData()
		})
	},
	destroyed () {
		document.body.removeEventListener('click', this.closeMenu)
	},
	methods: {
		closeMenu ($event) {
            if(!$event || !this.$refs.notifications.contains($event.target)) {
                this.showDropdownNotifications = false
            }
        },
		logOut () {
			localStorage.removeItem('token')
			this.$router.push('/login')
		},
		getData () {
			axios.get('notifications')
			.then((res) => {
				this.unread = res.data.count
				this.notifications = res.data.data
			})
		},
		onRead () {
			this.showDropdownNotifications = !this.showDropdownNotifications
			if(this.showDropdownNotifications && this.notifications.length > 0) {
					this.$nextTick(() => {
						this.$refs.dropdown.scrollTo({top: 0, left: 0, behavior: 'smooth'})
					})
				}
			if(this.unread) {
				this.unread = ''
				axios.post('notifications/read')
				.then(() => {
					this.unread = ''
				})
			}
		},
		deleteAll () {
			if(this.notifications.length > 0) {
				axios.post('notifications/delete')
				.then(() => {
					this.notifications = ''
				})
			}
		},
		getTime () {
			let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
			let bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
			let today = new Date()
			let day = today.getDay()
			let date = today.getDate()
			let month = today.getMonth()
			let year = today.getFullYear()
			let HH = today.getHours()
			let mm = today.getMinutes()
			let ss = today.getSeconds()
			HH = checkTime(HH)
			mm = checkTime(mm)
			ss = checkTime(ss)
			this.date = `${hari[day]}, ${date} ${bulan[month]} ${year}`
			this.time = `${HH}:${mm}:${ss}`
			setTimeout(() => {
				this.getTime()
			}, 1000)
			function checkTime (i) {
				if(i < 10) {
					i = `0${i}`
				}
				return i
			}
		},
	}
}
</script>

<style>
	.fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.1s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
	
</style>