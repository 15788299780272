import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
require('@/store/subscribe')
import './style.css'
import './assets/icons/icomoon/styles.min.css'
import './animate.min.css'
axios.defaults.baseURL = 'https://apikitb.ndpteknologi.com/api'

Vue.config.productionTip = false
Vue.use(require('vue-pusher'), {
  api_key: '738d5ec82fc127d8a5cc',
  options: {
      cluster: 'ap1',
      encrypted: true,
  }
});

store.dispatch('auth/setToken', localStorage.getItem('token'))
.then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

